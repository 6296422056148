import VCDom  from './vc';

export default VCDom.Render(
    'shopping-cart', 
    {
        render(props) {
            if(this.isAuthenticated && this.isUserLoginEnabled) {
                this.buildCartModal();
                
                this.html = document.querySelector('html');
                
                return {
                    updateCart: this.updateCart.bind(this),
                }
            }
        },
        props: {
            appName: String,
            siteHeader: Object
        },
        data: {
            isUserLoginEnabled: false,
            isAuthenticated: false,
            itemsInCart: [],
            shoppingCartItems: []
        },
        events(vc) {
            var that = this;
           
        },
        methods: {
            setItemsInCart(count) {
                this.siteHeader.cartItemsCountElement.text(count);

                count > 0 
                    ? this.siteHeader.cartItemsCountElement.removeClass('cart--empty') 
                    : this.siteHeader.cartItemsCountElement.addClass('cart--empty');
            },
            buildCartModal() {
                var that = this;
                this.dom
                .addClass('modal')
                .onResize(x => {
                    x.class(0, 'cart-modal--mobile', true)
                    .class(770, 'cart-modal--min', true)
                    .class(1050, 'cart-modal--medium', true)
                    .class(1300, 'cart-modal--max', true);
                })
                .addClass('cart-modal')
                    .addElem('div', header => {
                        header.addClass('cart-modal__header')
                        .addElem('h3', h3 => h3.text(this.appName));
                    }).addElem('ul', cnt => {
                        cnt.addClass('cart-modal__cnt');

                        that.siteHeader.cartDomElement.event('click', () => {
                            that.getShoppingCart(() => that.displayCartItems(cnt));
                        });

                        this.dom.addElem('div', closeBtn => {
                            closeBtn.addClass('modal__close')
                                .addClass('icon-close')
                                .event('click', () => {
                                    this.html.style.overflow = 'scroll';
                                    this.dom.removeClass('modal--is-visible');
                                    this.State.activeModals = this.State.activeModals.filter(function(item) {
                                        return item.name !== 'shopping-cart';
                                    });
            
                                    cnt.empty();
                                });
                        })
                    });
            },
            displayCartItems(cnt) {
                var that = this;
                let activeModalsCount = this.State.activeModals.length + 1;
                this.dom.addClass('modal--is-visible')
                   .styles({ 'zIndex': activeModalsCount * 100 });

                this.State.activeModals.push({ name: 'shopping-cart', zIndex: activeModalsCount }); 
               
                cnt.addElem('li', itemsBlk => {
                    itemsBlk.addClass('cart-modal__items-blk')
                    .addElem('div', content => {
                        content.bindAction(b => that.selectedItemsInCart() > 0 ? content.removeClass('empty') : content.addClass('empty'));

                        content.addClass('content')
                        .addElem('div', ctrl => {
                            ctrl.addClass('ctrl')
                            .addElem('h1', title => {
                                title.text('Shopping Cart')
                            })
                            .addElem('a', deselectAll => {
                                deselectAll
                                .attrs({ href: '#' })
                                .styles({ color: '#007185' })
                                .text('Deselect all items')
                                .event('click', () => {
                                    this.itemsInCart.forEach(function(itemData, i) {itemData.isSelected = false});
                                }).bindAction(() => {
                                    that.selectedItemsInCart() === 0
                                        ? deselectAll.addClass('sg-hide')
                                        : deselectAll.removeClass('sg-hide');
                                });
        
                                if(that.selectedItemsInCart() === 0) {
                                    deselectAll.addClass('sg-hide')
                                }
                            })
                            .addElem('a', selectAll => {
                                selectAll
                                .attrs({ href: '#' })
                                .styles({ color: '#007185' })
                                .text('Select all items')
                                .event('click', () => {
                                    this.itemsInCart.forEach(function(itemData, i) {itemData.isSelected = true});
                                }).bindAction(() => {
                                    that.selectedItemsInCart() !== 0
                                        ? selectAll.addClass('sg-hide')
                                        : selectAll.removeClass('sg-hide');
                                });
        
                                if(that.selectedItemsInCart() !== 0) {
                                    selectAll.addClass('sg-hide')
                                }
                            })
                            .addElem('label', priceLabel => {
                                priceLabel
                                .styles({ float: 'right', color: '#838383' })
                                .text('Price');
                            })
                            .addElem('hr', hr => {
                                hr.styles({
                                    border: 'none',
                                    height: '.5px',
                                    backgroundColor: '#DDD'
                                })
                            });
                        })                        
                        .addElem('ul', items => {
                            items.addClass('items-ul');

                            console.log('itemsInCart', that.itemsInCart);

                            this.itemsInCart.forEach(function(itemData, i) {
                                items.addElem('li', product => {
                                    product.addClass('items-ul-li')
                                    .addElem('div', deselectBlk => {
                                        deselectBlk
                                        .addClass('items-ul-li_deselect')
                                        .addElem('a', deselect => {
                                            deselect.attrs({ href: '#' })
                                            .event('click', () => itemData.isSelected = !itemData.isSelected)
                                            .bindAction(() => {
                                                itemData.isSelected
                                                    ? deselect.addClass('icon-check')
                                                    : deselect.removeClass('icon-check');   
                                            })
                                            if(itemData.isSelected) {
                                                deselect.addClass('icon-check')
                                            }
                                        })
                                    })
                                    .addElem('div', info => {
                                        info.addClass('items-ul-li_info')
                                        .addElem('div', itemImg => {
                                            itemImg.addClass('items-ul-li_info_img')
                                            .addElem('img', img => {
                                                img.attrs({ src: itemData.item.image_path })
                                            });
                                        })
                                        .addElem('div', itemDataBlk => {
                                            itemDataBlk.addClass('items-ul-li_info_data')
                                            .addElem('h2', itemTitle => {
                                                itemTitle.addClass('_title')
                                                .text(itemData.item.name);
                                            })
                                            .addElem('h3', itemCategory => {
                                                itemCategory.addClass('_subtitle').text(itemData.item.genre);
                                            })
                                            .addElem('hr', hr => {
                                                hr.styles({
                                                    position: 'absolute',
                                                    left: 0,
                                                    right: 0,
                                                    border: 'none',
                                                    height: '.5px',
                                                    backgroundColor: '#DDD'
                                                })
                                            })                                        
                                            .addElem('ul', ctrls => {
                                                ctrls.addClass('item-ctrls')
                                                .addClass('list-horizontal')
                                                .styles({
                                                   width: '200px'
                                                })
                                                .addElem('li', li => { 
                                                    li.styles({  })
                                                    .addElem('a', itemsCountCtrl => {
                                                        itemsCountCtrl.attrs({ href: '#' })
                                                        .addClass('items-count-ctrl')
                                                        .text('Oty:')
                                                        .addElem('input', count => {
                                                            count.val(itemData.count);
                                                            itemsCountCtrl.addElem('select', select => {
                                                                ['delete', 1, 2, 3, 4, 5, 6, 7, 8, 9, '10+'].forEach(function(item) {
                                                                    select.addElem('option', option => option.text(item).val(that.parseInt(item, 0)));
                                                                });
                                                                select.val('').event('change', (e) => {
                                                                    let val = that.parseInt(select.element.value, 10);
                                                                    setCountVal(val);
                                                                    itemData.count = val;
                                                                });
    
                                                                let onInputBlurHandler = () => {
                                                                    let val = that.parseInt(count.element.value, 10);
                                                                    setCountVal(val);
                                                                    itemData.count = val;
                                                                    count.element.removeEventListener('blur', onInputBlurHandler);
                                                                };
    
                                                                let setCountVal = (val) => {
                                                                    if(val === 0) {
                                                                        product.element.remove();
                                                                        select.val('');
                                                                        return true;
                                                                    }
                                                                    if(val < 10 || val > 10) {
                                                                        count.val(val);
                                                                        select.styles({ zIndex: 50 });
                                                                        count.removeClass('blk-spread');
                                                                        select.val('');
                                                                        return true;
                                                                    }
    
                                                                    if(val === 10) {
                                                                        count.val(1).element.focus();
                                                                        select.styles({ zIndex: -1 });
                                                                        count.addClass('blk-spread');
                                                                        
                                                                        count.element.addEventListener('blur', onInputBlurHandler);
                                                                    }
                                                                };
                                                            })
                                                        })
                                                    })
                                                 })
                                                .addElem('li', li => { 
                                                    li.styles({  })
                                                    .addElem('a', remove => {
                                                        remove.attrs({ href: '#' })
                                                        .text('Delete')
                                                        .event('click', () => {
                                                            product.element.remove();
                                                            itemData.count = 0;
                                                            that.dom.refreshState();

                                                            
                                                            console.log("this.State", that.State)
                                                            console.log("itemData", itemData)
                                                        })
                                                    })
                                                 })
                                            })
                                            .addElem('div', priceBlk => {
                                                priceBlk.addClass('_price')
                                                .text(`<b>$${itemData.item.price}</b>`);
                                            });
                                        })
                                    });
                                });
                            });
                        })
                        
;
                    });

                    this.html.style.overflow = 'hidden';
                })
                .addElem('li', cartActionBlk => {
                    cartActionBlk.addClass('cart-modal__action')
                    .styles({
                        fontFamily: "'Lato', sans-serif",
                    })
                    .addElem('h4', title => {
                        title.styles({
                            margin: 0,
                            padding: '10px 0 5px 0',
                            textAlign: 'center',
                            fontSize: '25px',
                            lineHeight: '40px',
                            textShadow: '2px 2px 0 rgba(71, 178, 152, .1)',
                            userSelect: 'none'
                        }).bindText(d => `Subtotal (${that.selectedItemsInCart()} items): <b>$${that.selectedItemsInCartSubtotalPrice()}</b>`, true);
                    })
                    .addElem('div', placeOrderBlk => {
                        placeOrderBlk.addClass('place-order')
                        .addElem('a', placeOrderBtn => {
                            placeOrderBtn.attrs({ href: '#'}).text('Place order').event('click', () => {
                                this.placeOrder();                            
                            })
                        })
                    })
                });
                
            },
            updateCart(productObj) {
                let items = this.itemsInCart.filter(function(item) { return item.id === productObj.id });

                if(items.length > 0) {
                    items[0].count = productObj.items_count;
                } 

                this.updateShoppingCart([productObj]);
            },
            parseInt(value, defaultValue) {
                if (!isNaN(parseInt(value))) {
                    return parseInt(value);
                }
                return defaultValue;
            },
            clearDefaults(e) {
                e.preventDefault();
                e.stopPropagation();
            },
            selectedItemsInCart() {
                return this.itemsInCart.filter(function(item) {return item.isSelected}).map(function(item) { return item.count }).reduce((a, b) => a + b, 0);
            },
            selectedItemsInCartSubtotalPrice() {
                return this.itemsInCart.filter(function(item) {return item.isSelected}).map(function(item) { return item.count * item.item.price }).reduce((a, b) => a + b, 0);
            },
            placeOrder() {
                console.log('Order Palaced.')
            },
            updateState() {
                var that = this;
                let isNew = true;
                this.State.viewedProducts.map(function(item) {
                    if(item.giftId === that.data.giftId) {
                        Object.assign(item, that.data);
                        isNew = false;
                    }
                });

                if(isNew) {
                    this.State.viewedProducts.push(Object.assign({}, this.data));
                }

                this.dom.refreshState();
            },
            getShoppingCart(func) {  
                var that = this;
                var xhr = new XMLHttpRequest();
                xhr.open("GET", 'ajax/get/shopping_cart', true);
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);

                            that.shoppingCartItems = response.data;

                            that.itemsInCart = [];

                            that.shoppingCartItems.forEach(function(product) {
                                let wrapperObj = {
                                    get id() {
                                        return product.id;
                                    },
                                    get item() {
                                        return product.item;
                                    },
                                    get orderedDatetime() {
                                        return product.ordered_datetime;
                                    },
                                    get count() {
                                        return product.items_count;
                                    },
                                    set count(newValue) {
                                        product.items_count = newValue;
                                        that.updateShoppingCart([product]);
                                        that.State.viewedProducts.map(function(item) {
                                            if(item.giftId === product.id) {
                                                item.itemsInCartCount = product.items_count;
                                            }
                                        });
                                        that.dom.refreshState();
                                    },
                                    get isSelected() {
                                        return product.is_active;
                                    },
                                    set isSelected(newValue) {
                                        product.is_active = newValue;
                                        that.updateShoppingCart([product]);
                                        that.dom.refreshState();
                                    }
                                }
                                
                                that.itemsInCart.push(wrapperObj)
                            });
        
                            func();        
                        }
                    }
                }
                xhr.send();      
            },
            addToShoppingCart() {  
                var that = this;

                let cart_items = JSON.stringify(this.shoppingCartItems)

                var xhr = new XMLHttpRequest();
                xhr.open("POST", 'ajax/post/shopping_cart', true);
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);
        
                            if(response && response.success === true) {
                                console.log('shopping cart got updated successfully')            
                            }               
                        }
                    }
                }
                xhr.send(cart_items);      
            },
            updateShoppingCart(items) {  
                let that = this;

                let res = this.shoppingCartItems;
                let totalItemsInCart = this.itemsInCart.map(function(item) { return item.count }).reduce((a, b) => a + b, 0);

                let xhr = new XMLHttpRequest();
                xhr.open("POST", 'ajax/put/shopping_cart', true);
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);

                            that.setItemsInCart(response.itemsInCartCount);
                        }
                    }
                }
                xhr.send(JSON.stringify(items));      
            },
            updateShoppingCartItem(cartItem) {  
                var that = this;

                let cart_item = JSON.stringify(cartItem)

                var xhr = new XMLHttpRequest();
                xhr.open("POST", 'ajax/put/shopping_cart/item', true);
                xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);
        
                            if(response && response.success === true) {
                                console.log('shopping cart item got updated successfully')            
                            }               
                        }
                    }
                }
                xhr.send(cart_item);      
            }
        }
    }
);
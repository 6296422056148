import VCDom  from './vc';

export default VCDom.Render(
    'site-header', 
    {
        render(props) {
            this.initCartDomElement();
            this.buildDom();

            return {
                cartItemsCountElement: this.dom.wrap(this.cartItemsCountElement),
                cartDomElement: this.dom.wrap(this.cartDomElement),
                headerElement: this.dom
            }
        },
        props: {
            appName: String
        },
        data: {
            fbLoginUrl: '',
            isUserLoginEnabled: false,
            isShoppingCartEnabled: false,
            isAboutSectionDisplayed: false,
            isTestimonialsSectionDisplayed: false,
            isAuthenticated: false,
            itemsInCart: [],
            shoppingCartItems: [],
            cartDomElement: {},
            cartItemsCountElement: {}
        },
        events(vc) {
            var that = this;
           
        },
        methods: {
            initCartDomElement() {
                if(!this.isAuthenticated) return;

                this.cartDomElement = document.createElement('a');
                this.cartItemsCountElement = document.createElement('span');
            },
            buildDom() {
                let that = this;

                let logoElemen = document.createElement('li');
                let navMenuElement = document.createElement('li');

                this.dom
                .addClass('header-section')
                .onResize(x => {
                    x.css({
                        position: 'absolute'
                    }).css(650, {
                        position: 'fixed'
                    })
                    .class(0, 'header-section--mobile', true)
                    .class(800, 'header-section--full', true);
                })
                .addElem('ul', cnt => {
                    cnt
                    .addClass('header')
                    .onResize(x => {
                        x.class(0, 'header--min', true)
                        .class(800, 'header--max', true)
                    })
                    .append(logoElemen, logoCnt => {
                        logoCnt.addClass('header_logo') 
                        .addElem('div', logo => {
                            logo.addClass('header_logo_blk')
                            .addElem('a', a => {
                                a.attrs({ href: '#site-wall', id: 'site-wall-link' })
                                .text(this.appName)
                            })
                        })
                    })
                    .append(navMenuElement, menuCnt => {
                        menuCnt.addClass('header_nav')
                        .addClass('nav')
                        .onResize(x => {
                            x.class(0, 'nav--mobile', true)
                            .class(800, 'nav--min', true)
                            .class(920, 'nav--max', true)
                        })
                        .addElem('ul', menu => {
                            menu.addClass('nav_opts');
                            if(!this.isAboutSectionDisplayed && !this.isTestimonialsSectionDisplayed){
                                menuCnt.addClass('nav--scaled')
                            }
                            if(this.isAboutSectionDisplayed){
                                menu.addElem('li', li => {
                                    li.addElem('a', a => {
                                        a.attrs({ href: '#info-block', id: 'info-block-link' })
                                        .text('About')
                                    })
                                })
                            }

                            menu.addClass('nav_opts')
                            .addElem('li', li => {
                                li.addElem('a', a => {
                                    a.attrs({ href: '#categories', id: 'categories-link' })
                                    .styles({ marginRight: !this.isAboutSectionDisplayed && !this.isTestimonialsSectionDisplayed ? '45px' : 0 })
                                    .text('Offers')
                                })
                            })
                            .addElem('li', li => {
                                li.addElem('a', a => {
                                    a.attrs({ href: '#gifts', id: 'gifts-link' })
                                    .text('Gifts')
                                })
                            }); 
                            
                            if(this.isTestimonialsSectionDisplayed){
                                menu.addElem('li', li => {
                                    li.addElem('a', a => {
                                        a.attrs({ href: '#info-block', id: 'info-block-link' })
                                        .text('About')
                                    })
                                })
                            }
                        });
                    })
                    .addElem('li', auth => {
                        auth.addClass('header_auth');

                        if(!this.isUserLoginEnabled) 
                            return;

                        let cartBtn = that.cartDomElement;
                        let cartItems = that.cartItemsCountElement;
                        this.isAuthenticated
                            ? this.loadUserData(data => {
                                that.buildUserInfoBlk(auth, data);

                                if(this.isShoppingCartEnabled){
                                    cnt.initElem('li', cart => {
                                        cart.addClass('header_cart')
                                        .append(cartBtn, a => {
                                            a.addClass('cart')
                                            .attrs({ href: "#" })
                                            .append(cartItems, span => {
                                                span.text(data.itemsInCartCount);
                                                if(data.itemsInCartCount < 1) {
                                                    span.addClass('cart--empty');
                                                }
                                            });
                                        });
    
                                        auth.element.after(cart.element);
                                    });
                                }                            
                            })
                            : this.buildLogInBlk(auth)
                    })
                    .addElem('li', menuIcon => {
                        menuIcon.addClass('header_menu_icon')
                        .onResize(x => {
                            x.css({
                                display: 'table-cell',
                            }).css(800, {
                                display: 'none',
                            });
                        })
                        .addElem('a', icon => {
                            icon
                            .addClass('nav-icon')
                            .addElem('div')
                            .attrs({ href: "#" })
                            .event('click', () => {
                                that.dom.toggleClass('header-section--fixed', isAdded => {
                                    isAdded
                                        ? icon.addClass('nav-icon--close-x')
                                        : icon.removeClass('nav-icon--close-x');
                                });
                            });
                        })
                    });
                })
            },
            buildLogInBlk(elem) {
                var that = this;
                elem.addClass('header_auth')
                .styles({
                    width: '10%'
                }).addElem('dev', cnt => {
                    cnt.addClass('header_auth_cnt')
                    .addElem('a', logIn => {
                        logIn.attrs({
                            href: this.fbLoginUrl
                        })
                        .addClass('login')
                        .text('Login')
                        .addElem('i', i => {
                            i.addClass('icon-login')
                        });
                    })
                });
            },
            buildUserInfoBlk(elem, userData) {
                elem.styles({
                    position: 'relative'
                })
                .onResize(x => {
                    x.class(0, 'header_auth--min', true)
                    .class(920, 'header_auth-max', true)
                })
                .addElem('dev', cnt => {
                    elem.event('mouseleave', (e) => {
                        cnt.removeClass('menu--visible');
                        this.dom.removeClass('minimize')            
                    });

                    cnt.addClass('header_auth_cnt')
                    .addClass('menu')
                    .addElem('a', logIn => {
                        logIn.addClass('menu-btn')
                        .attrs({ href: "#" })
                        .event('click', () => {
                            cnt.toggleClass('menu--visible', isAdded => {
                                isAdded 
                                    ? this.dom.addClass('minimize')
                                    : this.dom.removeClass('minimize')
                            });
                        })
                        .addElem('ul', ul => {
                            ul.addClass('auth');                            

                            if(userData.is_staff) {
                                logIn.styles({
                                    'float': 'right',
                                    'width': 'auto'
                                });

                                ul.styles({
                                    'padding': '0 20px 0 0',
                                    'width': 'auto'
                                })
                                .addElem('li', ui => {
                                    ui.addClass('auth__staff-info')
                                    .text(`${userData.username}`)
                                })
                                .addElem('li', i => {
                                    i.addClass('icon-chevron-left')
                                    .addClass('opacity')
                                });
                            } else {
                                ul.addElem('li', imgBlk => {
                                    imgBlk.addClass('auth__picture')
                                    .addElem('img', img => {
                                        img.attrs({
                                            height: "30",
                                            width: "30",
                                            src: userData.picture
                                        })
                                    })
                                })
                                .addElem('li', ui => {
                                    ui.addClass('auth__info')
                                    .text(`${userData.first_name} ${userData.last_name}`)
                                })
                                .addElem('li', i => {
                                    i.addClass('icon-chevron-left')
                                    .addClass('opacity')
                                });
                            }
                        });
                    })
                    .addElem('div', menuCnt => {
                        menuCnt.addClass('menu__dialog')
                        .addClass('menu__dialog--down')
                        .addElem('div', tri => {
                            tri.addClass('menu__tria-top')
                            .addClass('skyColor')
                            .addElem('div')
                        })
                        .addElem('ul', authData => {
                            authData.addClass('auth_menu');

                            if(userData.is_staff) {
                                authData.addElem('li', li => {
                                    li.addClass('auth_menu_user')
                                    .addElem('div', userName => {
                                        userName.addClass('full-name')
                                        .styles({
                                            margin: 0,
                                            letterSpacing: '2px'
                                        })
                                        .text(`${userData.username}`);
                                    })
                                })
                            } else {
                                authData.addElem('li', userPict => {
                                    userPict
                                    .addClass('auth_menu_user')
                                    .addElem('img', img => {
                                        img.attrs({ 
                                            src: userData.picture,
                                            height: "50",
                                            width: "50"
                                        })
                                    })
                                    .addElem('div', fullName => {
                                        fullName.addClass('full-name')
                                        .text(`${userData.first_name} ${userData.last_name}`)
                                    })
                                })
                            }

                            authData.addElem('li', lastLogin => {
                                lastLogin.addClass('last-login')
                                .addElem('label', span => span.text('Last login: '))
                                .addElem('span', span => span.text(userData.last_login))
                            })
                            .addElem('li', memberSince => {
                                memberSince.addClass('member-since')
                                .addElem('label', span => span.text('Member since: '))
                                .addElem('span', span => span.text(userData.date_joined))
                            })
                            .addElem('li', logout => {
                                logout.addClass('logout')
                                .addElem('a', a => {
                                    a.attrs({ href: "/logout" })
                                    .text('Logout')
                                    .addElem('i', i => i.addClass('icon-logout'))
                                })
                            })
                        })
                    });
                });
            },
            loadUserData(callback){  
                var that = this;
                var xhr = new XMLHttpRequest();
                xhr.open("GET", 'get/ajax/userdata', true);
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);
        
                            if(!response) return;
        
                            callback(response);
                        }
                    }
                }
                xhr.send();      
            }
        }
});
export default {
    State: {},
    Render: function(componentName, domObj) {
                var component = componentName ? document.querySelector(componentName) : document.body;
                //if(typeof name === 'string' || name instanceof String )

                function tryParseJson(str) {
                    try {
                        return JSON.parse(str);
                    } catch (e) {
                        return {};
                    }
                }

                return (props) => {
                    let vc = Dom(component);
                    vc.componentName = componentName;
                    vc.params = props;
                    vc.data = domObj.data;

                    let domData = component.attributes['dom-data'];

                    if(componentName && domData) {

                        let dataKeys = Object.keys(vc.data);
                        let domObj = tryParseJson(domData.nodeValue);
                        let domDataKeys = Object.keys(domObj);

                        for (var key of domDataKeys) {
                            if(dataKeys.includes(key)) {
                                vc.data[key] = domObj[key];
                            }
                        }

                        component.removeAttribute("dom-data");

                        // for (var i = 0, atts = component.attributes, count = atts.length; i < count; i++){
                        //     let att = atts[i];   

                        //     vc.attrData[att.nodeName] = att.nodeValue;

                        //     // vc.attrData.push({ key: att.nodeName, value: att.nodeValue });
                        // }
                    }

                    Validator.ObjectProperties(props, domObj.props);

                    Object.assign(domObj.props, props);

                    if(domObj.export) {
                        Object.assign(domObj.export, domObj.methods);
                    }


                    let mergedObjects = Object.assign(domObj.methods, domObj.props, domObj.data, domObj.events, { self: utitilyObj, dom: vc, State: this.State, Observable: Observable  });
                    let result = domObj.render.call(mergedObjects, props);
                    domObj.events.call(mergedObjects, vc);
                    domObj.methods = Object.assign(domObj.methods, domObj.props, domObj.data, { self: utitilyObj, dom: vc, data: domObj.data, State: this.State, Observable: Observable });
                    

                    let methods = Object.keys(domObj.methods);
                    for (var key of methods) {
                        let method = domObj.methods[key];
                        let type = typeof domObj.methods[key];

                        // if(type === 'function' && key === 'buildGiftModal') {
                        //     // domObj.methods[key].bind(domObj.props);
                        //     domObj.methods[key].message = 'hello';
                        //     Object.assign(domObj.props, domObj.methods[key]);
                        // }
                    }

                    return result;
                }
            },
            $: function(tag, func) {
                let elements = document.querySelectorAll(tag);

                if(elements.length === 1){
                    return Dom(elements[0])
                } else {
                    for (let i = 0; i < elements.length; i++) {

                        let dom = new Dom(elements[i]);

                        if(func) {
                            Validator.Function(func);
                            func(dom);
                        }
                        
                        return dom;
                    }
                }
            },
            create: function(elementName, func) {
                if(func) {
                    Validator.Function(func);
                    func(dom);
                }

                let element = document.createElement(elementName);
                document.body.appendChild(element);
                return Dom(element);
            },
            init: function(elementName, func) {
                if(func) {
                    Validator.Function(func);
                    func(dom);
                }

                var element = document.createElement(elementName);
                var dom = Dom(element);
                return dom;
            }
}

var dataBindFuncArray = [];

function Dom(element) {
    element = element ? element : document;
    
    var _vars = {};
    var onResiseFuncArray = [];
    var onResiseCSSArray = [];
    var onResizeClassArray = [];

    function css(styles) {
        var keys = Object.keys(styles);

        for (var key of keys) {
            element.style[key] = styles[key];
        }
    }

    let onResizeDoObject = {
        css: css
    }

    function addOnResizeListener() {
        if(onResiseFuncArray.length === 1) {
            // window.onresize = function(){ }; 
            window.addEventListener("resize", function() {        
                var funcArray = onResiseFuncArray.map(function(obj){
                    obj.applied = false;
                    return obj;
                });
            
                funcArray.forEach(function(obj) {
                    obj.func();
                });      
            });
        }
    }

    function onSizeFuncObject(size, func) {
        return { 
            size: size, 
            applied: false,
            func: () => {
                if((window.innerWidth < size || size == null) && !onResiseFuncArray.some(x => x.applied === true)) {
                    func(onResizeDoObject);
                    funcObj.applied = true;
                    console.log('size ', size);
                }
            }
        }
    }

    function tryParseInt(value, defaultValue) {
        if (!isNaN(value) && !isNaN(parseInt(value))) {
            return parseInt(value);
        }
        
        return defaultValue;
    }

    return {
        componentName: null,
        data: {},
        params: { message: 'default message' },
        element: element,
        vars: _vars,
        domCache: {},
        cache: {},
        wrap(element) {
            element = element;
            return Dom(element);
        },
        create(name) {
            element = document.createElement(name);
            // document.body.appendChild(element);
            return Dom(element);
        },
        initElem(name, func) {
            var child = document.createElement(name);
            var chilDom = Dom(child);
            if(func) func(chilDom);
            return this;
        },
        addElem(name, func) {  
            var child = document.createElement(name);
            element.appendChild(child);
            var childDom = Dom(child);  
            var parentElem = Dom(element);
            childDom.vars = this.vars;
            childDom.domCache = this.domCache;
            childDom.data = this.data;
            if(func) func(childDom, parentElem);
            return this;
        },
        appendDom: function(childDom, func) {
            let par = childDom.element.parentNode;
            element.appendChild(childDom.element);
            if(func) {
                childDom.vars = this.vars;
                childDom.domCache = this.domCache;
                childDom.data = this.data;
                func(childDom, this);
            }
            return this;
        },
        append: function(child, func) {
            element.appendChild(child);
            if(func) {
                var childDom = Dom(child);  
                var parentElem = Dom(element);
                childDom.vars = this.vars;
                childDom.domCache = this.domCache;
                childDom.data = this.data;
                func(childDom, parentElem);
            }
            return this;
        },
        childrenAsync: function(childName, childCount) {
            var elems = [];

            for (var i = 0; i < childCount; i++) {
                var elem = document.createElement(childName);
                elems.push(elem);
                element.appendChild(elem);
            }

            var result = {
                elems: elems,
                [Symbol.iterator]: function() {
                    let i = 0;
                    return {
                        next: () => ({
                            value: this.elems[i++],
                            done: i > this.elems.length
                        })
                    };
                }
            };

            result.atElement = (index, func) => {
                var domElem = Dom(elems[index]);
                if (func) {
                    func(domElem);
                    return this;
                }
                return Dom(elems[index]);
            };
            result.atElementAsync = (index) => Promise.resolve(Dom(elems[index]));;

            return Promise.resolve(result);
        },
        findById: function(id) {
            var element = document.getElementById(id);
            return Dom(element);
        },
        addClass: function(className) {
            element.classList.add(className);
            return this;
        },
        removeClass: function(className) {
            element.classList.remove(className);
            return this;
        },
        toggleClass: function(className, func) {
            let isAdded = element.classList.contains(className);

            isAdded 
                ? element.classList.remove(className)
                : element.classList.add(className);

            if(func) {
                Validator.Function(func);
                func(!isAdded);
            }

            return this;
        },
        addId: function(id) {
            element.id = id;
            return this;
        },
        styles(styles) {
            css(styles);
            return this;
        },
        onResizeCss(func) {
            css(func(window.innerWidth));

            window.onresize = function(){
                css(func(window.innerWidth));
            };

            return this;
        },
        onSize(size, func) {
            let funcObj = onSizeFuncObject(size, func);

            var res = !onResiseFuncArray.some(x => x.applied === true);
            
            //funcObj.func();

            onResiseFuncArray.push(funcObj); 
            return this;
        },
        watch() {
            onResiseFuncArray.sort(function(a, b) {
                return a.size > b.size;
            });

            addOnResizeListener();
        },
        onResize(callBackFunc) {
            let obj = {
                css(param1, param2) {
                    if (param1 !== null && typeof param1 == 'object'){
                        let styles = param1;
                        let cssRuleObj = { 
                            size: null, 
                            active: false,
                            styles: styles
                        };

                        onResiseCSSArray.push(cssRuleObj);
                        cssRulesRunner(cssRuleObj, onResiseCSSArray.length - 1);
                    } else if (tryParseInt(param1, null) !== null && param2 !== null && typeof param2 == 'object') {
                        let size = param1;
                        let styles = param2;

                        let cssRuleObj = { 
                            size: size, 
                            active: false,
                            styles: styles
                        };

                        onResiseCSSArray.push(cssRuleObj);
                        cssRulesRunner(cssRuleObj, onResiseCSSArray.length - 1);

                        // let size = param1;
                        // let func = param2;
                        // func(styles => {
                        //     onResiseCSSArray.push({ 
                        //         size: size, 
                        //         active: false,
                        //         styles: styles
                        //     });

                        //     cssRulesRunner(size, styles)
                        // });
                    }
                    
                    return this;
                },
                class(size, className, isOneTimeRule = false) {
                    let ruleObj = { size: size, className: className, active: false };
                    onResizeClassArray.push(ruleObj);

                    if(isOneTimeRule) {
                        classRulesRunner(ruleObj, onResizeClassArray.length);
                    }
                    
                    return this;                    
                },
                case(size, func, isOneTimeRule = false) {
                    let ruleObj = { size: size, func: func, active: false, isOneTimeRule: isOneTimeRule };
                    let isApplied = rulesRunner(ruleObj, onResiseFuncArray.length);

                    if(!isOneTimeRule || !isApplied){
                        onResiseFuncArray.push(ruleObj); 
                    }
                    if(isOneTimeRule && isApplied){
                        console.log('One time rule applied: ', ruleObj);
                    }
                    
                    return this;
                }
            }

            let cssRulesRunner = (obj, i) => {
                let size = obj.size ? obj.size : 0;

                if((window.innerWidth > size && !onResiseCSSArray.some(x => x.size > size && x.size < window.innerWidth)) && !obj.active) {
                    onResiseCSSArray.map(function(item, index) { 
                        item.active = index === i;
                        if(index === i || item.size == null) {
                            css(item.styles);
                        }
                    });

                    return true;                                     
                }

                return false;
            }

            let classRulesRunner = (obj, i) => {
                let size = obj.size;

                if((window.innerWidth > size && !onResizeClassArray.some(x => x.size > size && x.size < window.innerWidth)) && !obj.active) {
                    onResizeClassArray.map(function(item, index) { 
                        if(item.active === true) {
                            item.active = false;
                            element.classList.remove(item.className)
                        } else if(item.active === false && item.size === obj.size) {
                            item.active = true;
                            element.classList.add(item.className);
                        }                     
                    });

                    return true;                                     
                }

                return false;
            }

            let rulesRunner = (obj, i, removeRuleFunc) => {
                if((window.innerWidth > obj.size && !onResiseFuncArray.some(x => x.size > obj.size && x.size < window.innerWidth)) && !obj.active) {
                    obj.func(obj.size);

                    if(obj.isOneTimeRule === true && typeof removeRuleFunc === 'function') {
                        removeRuleFunc();
                        console.log('One time rule applied on resize: ', obj);
                    }

                    onResiseFuncArray.map(function(item, index) { 
                        item.active = index === i;
                    });
                    
                    return true;
                }

                return false;
            }

            let removeRule = (index, array) => array.splice(index, 1);

            //addOnResizeListener();
            window.addEventListener("resize", function() {        
                // onResiseCSSArray.forEach(function(obj) {
                //     cssRulesRunner(obj.size, obj.styles);
                // });   


                for (let i = 0; i < onResiseCSSArray.length; i++) {
                    let obj = onResiseCSSArray[i];
                    if(cssRulesRunner(obj, i)) break;
                }

                for (let i = 0; i < onResizeClassArray.length; i++) {
                    let obj = onResizeClassArray[i];
                    if(classRulesRunner(obj, i)) break;
                }
                
                
                onResiseFuncArray.forEach((obj, index, array) => {
                    rulesRunner(obj, index, () => removeRule(index, array))
                    //console.log('Value of this: ', this); //Value of this:  Window {window: Window, self: Window, document: document, name: '', location: Location, …}
                });
                // onResiseFuncArray.forEach(function(obj, index) {
                //     rulesRunner(obj, () => removeRule(index, this));
                //     console.log('Value of this: ', this);  //Value of this:  (2) [{…}, {…}]
                // }, onResiseFuncArray);
            });


            //cssRulesRunner();


            if(callBackFunc) callBackFunc(obj);

            return this;
        },
        width: function(value) {
            element.width = value;
            return this;
        },
        height: function(value) {
            element.height = value;
            return this;
        },
        attrs: function(attrs) {
            var names = Object.keys(attrs);

            for (var name of names) {
                element.setAttribute(name, attrs[name]);
            }

            return this;
        },
        getAttr: function(name) {
            return element.getAttribute(name);
        },
        text(text) {
            element.innerHTML = text;
            return this;
        },
        val(value) {
            element.value = value;
            return this;
        },
        bindText(func, isObsorvable) {
            Validator.Function(func);

            if(this.data) {
                let doFunc = () => element.innerHTML = func(this.data);
                doFunc();

                if(isObsorvable === true) {
                    dataBindFuncArray.push(doFunc);
                }
            }
            
            return this;
        },
        bindValue(func, isObsorvable) {
            Validator.Function(func);

            if(this.data) {
                let doFunc = () => element.value = func(this.data);
                doFunc();

                if(isObsorvable === true) {
                    dataBindFuncArray.push(doFunc);
                }
            }
            
            return this;
        },
        bindAction(func) {
            Validator.Function(func);

            if(this.data) {
                let doFunc = () => func(this.data);
                doFunc();

                dataBindFuncArray.push(doFunc);
            }
            
            return this;
        },
        bindAttr(attr, func) {
            Validator.String(attr);
            Validator.Function(func);

            if(this.data) {
                element.setAttribute(attr, func(this.data));
                dataBindFuncArray.push(() => element.setAttribute(attr, func(this.data)));
            }
            
            return this;
        },
        refreshState() {
            // console.log('dataBindFuncArray ', dataBindFuncArray);
            dataBindFuncArray.forEach(function(func) {
                func();
            });
        },
        cacheAs(name) {
            cache[name] = element;
        },
        cache(name) {
            return cache[name];
        },
        event(name, func) {
            element.addEventListener(name, (e) => {
                e.preventDefault();
                e.stopPropagation();
                func(e);
            });
            return this;
        },
        empty: function() {
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            return this;
        }
    };
}

let Observable = {
    forEachInit(array, func) {
        // for(var obj in array) {
        for (let i = 0; i < array.length; i++) {
            func(this.init(array[i]), i);
        }
    },
    init: function(targetObj) {
        let subscriptions = [];
        let func = (propNameStr, func) => {
            if(!targetObj.hasOwnProperty(propNameStr)) {
                throw `Object ${targetObj} does not contain property ${propNameStr}.`
            }
            subscriptions.push({ prop: propNameStr, callBack: func });
        };
        Object.defineProperty(targetObj, 'AddSubscription', {
            value: func,
            writable: false,
            enumerable: false,
            configurable: false
        });

        let initObject = (obj, prop) => {
            Object.defineProperty(obj, prop, {
                    get () {
                        return targetObj[prop];
                    },
                    set (value) {
                        targetObj[prop] = value;
                        subscriptions.forEach(function(func) {
                            func(targetObj[prop]);
                        })
                    }
                }
            );
        };

        let initProxyObject = () => {
            return new Proxy(targetObj, {
                get(obj, prop) {
                    return prop in obj ? obj[prop] : null;
                },
                set(obj, prop, newval) {
                    obj[prop] = newval;
                    subscriptions.forEach(function(func) {
                        func();
                    })

                    return true;
                }
            });
        };

        return {
            defineProperty(prop, value) {                            
                Object.defineProperty(targetObj, prop, {
                    get () {
                        return value;
                    },
                    set (newValue) {
                        if(value !== newValue) {
                            value = newValue;
                            subscriptions.filter(function(item) { return item.prop === prop }).forEach(function(item) {
                                item.callBack();
                            })
                        }       
                    }
                })
                return this;
            },
            subscribe() {
                let obj = {};
                for(var name in valueObj) {
                    initObject(obj, name);
                }
                return obj;
            },
            subscribeProxyObject() {
                return initProxyObject();
            },
            observableObject: targetObj
        }
    },
}

let utitilyObj = {
    findAll(name) {
        var elements = document.querySelectorAll(name);
        let domElementsArray = [];
        
        for (let i = 0; i < elements.length; i++) {
            resultArray.push(Dom(elements[i]));
        }

        var result = {
            elems: domElementsArray,
            [Symbol.iterator]: function() {
                let i = 0;
                return {
                    next: () => ({
                        value: this.elems[i++],
                        done: i > this.elems.length
                    })
                };
            }
        };

        result.atElement = (index, func) => {
            var domElem = elems[index];
            if (func) {
                func(domElem);
                return this;
            }
            return elems[index];
        };

        return result;
    },
    eventListenerAll(eventName, targetName, func) {
        var elements = document.querySelectorAll(targetName);
        
        for (let i = 0; i < elements.length; i++) {
            
            elements[i].addEventListener(eventName, (e) => {
                let domElem = new Dom(e.currentTarget);
                func(e, domElem);
            });
        }
    }
}

let Validator = {
    String(str) {
        let isString = typeof str === 'string' || str instanceof String;
        
        if(!isString) {
            throw `Object ${str} is not of type 'String'.`
        }
    },
    Function(obj) {
        if(typeof obj !== 'function') {
            throw `Object ${obj} is not of type 'function'.`
        }
    },
    ObjectProperties(valueObj, dataTypesObj) {
        let dataTypeKeys = Object.keys(dataTypesObj);
        let errors = [];

        for (var key of dataTypeKeys) {
            let type = typeof valueObj[key];
            let expectedType = dataTypesObj[key].name.toLowerCase();

            if(valueObj[key] && type !== expectedType){
                errors.push(`Property ${key} is of type '${type}', but expected type is '${expectedType}'.`)
            }           
        }

        if(errors.length > 0) {
            throw errors.join(';')
        }
    },
    ObjectProperties2(valueObj, dataTypesObj) {
        let dataTypeKeys = Object.keys(dataTypesObj);
        let errors = [];

        for(var name in valueObj) {
            alert(name);
            var value = valueObj[name];
            alert(value);

            
        }

        for (var key of dataTypeKeys) {
            let type = typeof valueObj[key];
            let expectedType = dataTypesObj[key].name.toLowerCase();

            if(valueObj[key] && type !== expectedType){
                errors.push(`Property ${key} is of type '${type}', but expected type is '${expectedType}'.`)
            }           
        }

        if(errors.length > 0) {
            throw errors.join(';')
        }
    }
}

//export default Dom;
import VCDom  from './vc';

export default VCDom.Render(
    'get-in-touch', 
    {
        render(props) {
            this.html = document.querySelector('html');
            this.dom.addClass('modal');
            this.self.eventListenerAll('click', '.js-get-in-touch', (e) => {
                e.preventDefault();
                e.stopPropagation();

                this.buildModal();
            });
            
            return { }
        },
        props: {
            appName: String
        },
        data: {
            phone: '+16047217445',
            email: 'yellowsparrowgift@gmail.com',
            facebookLink: 'https://www.facebook.com/YellowSparrowGift',
            instagramLink: 'https://www.instagram.com/yellowsparrowgift/?igshid=YmMyMTA2M2Y%3D'
        },
        events(vc) {},
        methods: {
            closeModal() {
                this.html.style.overflow = 'scroll';
                this.dom.removeClass('modal--is-visible');
                // this.State.activeModals = this.State.activeModals.filter(function(item) {
                //     return item.name !== 'get-in-touch';
                // });

                this.dom.empty();
            },
            buildModal() {
                this.dom
                .addClass('modal--is-visible')
                .onResize(x => {
                    x.class(0, 'modal--min', true)
                    .class(730, 'modal--max', true);
                })
                .addElem('ul', modalInner => {
                    modalInner.addClass('modal__inner')
                    modalInner.addClass('get-in-touch-modal')
                    .addElem('div', closeBtn => {
                        closeBtn.addClass('modal__close')
                            .addClass('icon-close')
                            .event('click', () => this.closeModal());
                    })
                    .addElem('div', modalCnt => {
                        modalCnt.addClass('get-in-touch-modal_cnt')
                        .addElem('div', logoCnt => {
                            logoCnt.addClass('get-in-touch-modal_cnt_logo')
                            .addElem('i', i => i.addClass('_i_logo'))
                        })
                        .addElem('div', contacts => {
                            contacts.addClass('get-in-touch-modal_cnt_contacts')
                            .addElem('ul', ul => {

                                if(this.phone || this.email || this.facebookLink || this.instagramLink) {
                                    let contactsList = [ 
                                        { icon: '_i_phone', value: this.phone, link: `<a href="tel:${this.phone}" target="_blank">${this.phone}</a>` }, 
                                        { icon: '_i_mail', value: this.email, link: `<a href="mailto:${this.email}" target="_blank">${this.email}</a>` }, 
                                        { icon: '_i_facebook', value: this.facebookLink, link: `<a href="${this.facebookLink}" target="_blank">https://www.facebook.com/...</a>` }, 
                                        { icon: '_i_instagram', value: this.instagramLink, link: `<a href="${this.instagramLink}" target="_blank">https://www.instagram.com/...</a>` }
                                    ];
    
                                    contactsList.forEach(function(contact) {
                                        if(!contact.value) return;
                                        ul.addElem('li', li => {
                                            li.addClass('_icon')
                                            .addClass(contact.icon)
                                            .text(contact.link)
                                            .addElem('a', a => {
                                                a.attrs({ href: '#' })
                                                .addClass('_i_copy')
                                                .event('click', () => {
                                                    navigator.clipboard.writeText(contact.value);
                                                    a.element.previousElementSibling.classList.add('animate');
                                                    li.addClass('coppied-animate');
                                                    
                                                    setTimeout(function() {
                                                        a.element.previousElementSibling.classList.remove('animate');
                                                        li.element.classList.remove('coppied-animate');
                                                    }, 800)
                                                })
                                            });
                                        });
                                    });   
                                } else {
                                    ul.addElem('li', li => {
                                        li.addClass('get-in-touch-modal_info_row')
                                        .addElem('h3', h3 => {
                                            h3.text("No contact methods.")
                                        });
                                    });
                                }                              
                            });
                        });
                    });                    

                    this.html.style.overflow = 'hidden';
                });
            }
        }
    }
);
import $ from '../../../../node_modules/jquery/dist/jquery.js';
import waypoints from '../../../../node_modules/waypoints/lib/noframework.waypoints.min.js';
import smoothScroll from '../../../../node_modules/jquery-smooth-scroll/jquery.smooth-scroll.js';

//$.fn.load = function(callback){ $(window).on("load", callback) };

class StickyHeader{
    constructor(){
        this.lazyImages = $(".lazyload");
        this.siteHeader = $(".header-section");
        this.pageSections = $(".page-section");
        this.headerLinks = $(".nav a");
        this.siteWallLinks = $("#site-wall-link");
        this.createPageSectionWaypoints();
        this.addSmoothScrolling();
        this.refreshWaypoints();
    }

    refreshWaypoints(){
        this.lazyImages.load(function(){
            Waypoint.refreshAll();
        });
    }

    addSmoothScrolling(){
        this.headerLinks.smoothScroll();
        this.siteWallLinks.smoothScroll();
    }

    createPageSectionWaypoints(){
        var that = this;
        this.pageSections.each(function(){
            var currentPageSection = this;
            new Waypoint({
                element: currentPageSection,
                handler: function(direction){
                    if(direction == "down"){
                        var matchingHeaderLink = currentPageSection.getAttribute("data-matching-link");
                        that.headerLinks.removeClass("is-current-link");
                        $(matchingHeaderLink).addClass("is-current-link");
                    }
                },
                offset: "18%"
            });

            new Waypoint({
                element: currentPageSection,
                handler: function(direction){
                    if(direction == "up"){
                        var matchingHeaderLink = currentPageSection.getAttribute("data-matching-link");
                        that.headerLinks.removeClass("is-current-link");
                        $(matchingHeaderLink).addClass("is-current-link");
                    }
                },
                offset: "-40%"
            })
        })
    }
}

export default StickyHeader;
import VCDom  from './vc';

export default VCDom.Render(
    'site-wall', 
    {
        render(props) {
            this.buildDom();            

            return {}
        },
        props: {
            siteHeader: Object
        },
        data: {
            title: '',
            subtitle: '',
            description: '',
            radius: 20,
            canvas: null
        },
        events(vc) {},
        methods: {
            buildDom() {
                let that = this;
                this.dom
                .styles({
                    height: '654px',
                    width: '100%'
                })
                .onResize(x => {
                    x.class(0, 'default', true)
                    .class(580, 'at580', true)
                    .class(800, 'at800', true)
                    .class(950, 'at950', true)
                    .class(1300, 'at1300', true);
                })
                .addClass('site-wall')
                // .addElem('picture', picture => {
                //     picture
                //         .addElem('source', source => {
                //             source.attrs({
                //                 srcset: '../dist/assets/images/ysg_wall_bg.svg 1920w, ../dist/assets/images/ysg_wall_bg.svg 3840w',
                //                 media: '(min-width: 1380px)'
                //             });
                //         })
                //         .addElem('source', source => { 
                //             source.attrs({
                //                 srcset: '../dist/assets/images/site-wall--medium.jpg 1380w, ../dist/assets/images/site-wall--medium-hi.jpg 2760w',
                //                 media: '(min-width: 1100px)'
                //             });
                //         })
                //         .addElem('source', source => { 
                //             source.attrs({
                //                 srcset: '../dist/assets/images/site-wall--small.jpg 1000w, ../dist/assets/images/site-wall--small-hi.jpg 1880w',
                //                 media: '(min-width: 640px)'
                //             });
                //         })
                //         .addElem('img', img => { 
                //             img.addClass('site-wall__image')
                //             .attrs({
                //                 srcset: '../dist/assets/images/site-wall--smaller.jpg 640w, ../dist/assets/images/site-wall--smaller-hi.jpg 1280w',
                //                 alt: 'Wall view'
                //             });
                //         });
                // })
                
                .addElem('div', div => {
                    div.addClass('site-wall__animation')
                   
                    for (var i = 0; i < 4; i++) {
                        div.addElem('i');
                    }

                    let buletsCount = 100;
                   
                    for (var i = 0; i < buletsCount; i++) {
                        div.addElem('div', d => {
                            d.styles({ marginLeft: `${(i/buletsCount)*100}%`, 'animation-duration': Math.random() * (25 - 8) + 8 +'s' });
                            // d.element.style.setProperty('animation-duration', Math.random() * (25 - 8) + 8 +'s');
                        });
                    }
                })
                

                this.dom

                .addElem('div', cnt => {
                    cnt.addClass('site-wall__text-content')
                        .addClass('site-wall--text-align')
                        .addElem('div', wrapper => {
                            wrapper.addClass('wrapper')
                                .addElem('h1', title => {
                                    title.addClass('site-wall__title')
                                        .text(this.title);

                                    this.createHeaderWaypoint(title);
                                })
                                .addElem('h2', subtitle => {
                                    subtitle.addClass('site-wall__subtitle')
                                        .text(this.subtitle);
                                })
                                .addElem('p', description => {
                                    description.addClass('site-wall__description')
                                        .text(this.description);
                                })
                                .addElem('a', getInTouchBtn => {
                                    getInTouchBtn.addClass('site-wall__get-in-touch-btn')
                                        .text('Get In Touch')
                                        .attrs({ href: "#" })
                                        .addClass('js-get-in-touch')
                                        .event('click', () => this.siteHeader.headerElement.addClass("header-section--min"));
                                });
                        });
                });
            },
            createHeaderWaypoint(title) {
                var that = this;
                new Waypoint({
                    element: title.element,
                    handler: function(direction) {
                        if(direction == "down") {
                            that.siteHeader.headerElement.addClass("header-section--min");
                        }
                        else {
                            that.siteHeader.headerElement.removeClass("header-section--min");
                        }
                    }
                });
            },
            getPointsArray(width, height) {
                let pointsArray = [];

                for (var i = 0; i < 50; i++) {
                    let sign = i/2 > 0 ? 1 : -1
                    pointsArray.push({
                        x: Math.floor((Math.random() * width - this.radius) + 0),
                        y: Math.floor((Math.random() * height - this.radius) + 0),
                        xStep: Math.floor((Math.random() * 5) + 1) * sign,
                        yStep: Math.floor((Math.random() * 5) + 1) * sign
                    })
                }

                return pointsArray
            },
            degToRad(degrees) {
                return (degrees * Math.PI) / 180;
            }
        }
    }
);
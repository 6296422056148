import VCDom  from './vc';

export default VCDom.Render('gift-modal', 
    {
        render(props) {
            this.buildGiftModal();

            this.html = document.querySelector('html');
            
            return {}
        },
        props: {
            appName: String,
            shoppingCart: Object
        },
        data: {
            isUserLoginEnabled: false,
            isAuthenticated: false,
            isShoppingCartEnabled: false,
            product: {},
            giftId: -1,
            giftName: null,
            giftDescription: null,
            giftPrice: 0,
            itemsCount: 1,
            itemsInCartCount: 0,
            imagePath: null            
        },
        events(vc) {
            var that = this;
            this.self.eventListenerAll('click', '.js-gift', (e, domElem) => {
                this.clearDefaults(e);
                let id = this.tryParseInt(domElem.getAttr('data-gift-id'), 0);
                if(id > 0) {
                    that.displayGiftModal(id)
                }                
            });
        },
        export: {
            // hello: methods.hello
        },
        methods: {
            displayGiftModal(giftId) {
                var that = this;
                let activeModalsCount = this.State.activeModals.length + 1;
                this.dom.addClass('modal--is-visible')
                    .styles({ 'zIndex': activeModalsCount * 100 });

                this.State.activeModals.push({ name: 'gift-modal', zIndex: activeModalsCount });

                this.html.style.overflow = 'hidden';

                let product = this.State.viewedProducts.filter(function(item) { return  item.giftId === giftId })[0];
                
                if(product) {
                    Object.assign(this.data, product);

                    console.log("this.data spet 2", this.data)

                    this.dom.refreshState();
                    
                    this.dom.element.dispatchEvent(new CustomEvent('datachanged', { detail: this.data }));

                    if(this.data.giftId === giftId) return;
                }

                

                if(!product) {
                    this.loadGiftData(950, giftId, (data) => {
    
                        this.data.giftId = giftId;
                        this.data.giftName = data.name;
                        this.data.giftDescription = data.description;
                        this.data.giftPrice = data.price;
                        this.data.itemsCount = data.itemsInCartCount > 0 ? 0 : 1;
                        this.data.itemsInCartCount = data.itemsInCartCount;
                        this.data.imagePath = data.image_path;

                        const event = new Event('dataready');
                        this.dom.element.dispatchEvent(event);
                        this.dom.element.dispatchEvent(new CustomEvent('datachanged', { detail: this.data }));

                        this.updateState(); 
                    }); 
                }
            },
            updateState() {
                var that = this;
                let isNew = true;
                this.State.viewedProducts.map(function(item) {
                    if(item.giftId === that.data.giftId) {
                        Object.assign(item, that.data);
                        isNew = false;
                    }
                });

                if(isNew) {
                    this.State.viewedProducts.push(Object.assign({}, this.data));
                }

                this.dom.refreshState();
            },
            initDataModel(giftId, data) {
                let product = {
                    giftId: giftId,
                    giftName: data.name,
                    giftDescription: data.description,
                    giftPrice: data.price,
                    itemsCount: 1,
                    itemsInCartCount: data.itemsInCartCount,
                    imagePath: data.image_path
                }

                this.State.viewedProducts.push(product);
                Object.assign(this.data, product);
            },
            buildGiftModal() {
                var that = this;
                
                this.dom
                .addClass('modal')
                .addClass('modal--gift')
                .addClass('scrool-bar')
                .addElem('div', closeBtn => {
                        closeBtn.addClass('modal__close')
                                .addClass('icon-close')
                                .event('click', () => {
                                    this.html.style.overflow = 'scroll';
                                    this.dom.removeClass('modal--is-visible');
                                    this.State.activeModals = this.State.activeModals.filter(function(item) {
                                        return item.name !== 'gift-modal';
                                    });
                                });
                    })
                    .addElem('div', header => {
                        header
                        .addClass('gift-modal__header')
                        .styles({
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '60px'
                        }).addElem('h3', h3 => {
                            h3.styles({
                                margin: 0,
                                padding: '4px 0',
                                textAlign: 'center',
                                fontFamily: 'Rosalinda',
                                fontSize: '35px',
                                lineHeight: '60px',
                                textShadow: '2px 2px 0 rgba(71, 178, 152, .1)',
                                userSelect: 'none'
                            }).text(this.appName);
                        });
                    }).addElem('div', cnt => {
                        cnt.styles({
                            position: 'absolute',
                            top: '60px',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            marginRight: '-15px',
                            overflowY: 'scroll',
                            borderTop: 'solid 1px #c0c0c0'
                        });

                        let onDataReadyHandler = (event) => {
                            that.buildGiftModalContent(cnt);
                            this.dom.element.removeEventListener('dataready', onDataReadyHandler);
                        }

                        this.dom.element.addEventListener('dataready', onDataReadyHandler);
                    });
            },
            buildGiftModalContent(cnt) {
                var that = this;

                cnt.addElem('div', imgBlk => {
                    imgBlk.addClass('gift-modal__img');
                    imgBlk.onResize(x => {
                        x.css({
                            float: 'left',
                            position: 'relative',
                            width: 'calc(100%)',
                            height: 'calc(100%)',
                        }).css(550, {
                            right: 0,
                        }).css(650, {
                            right: 0,
                        }).css(790, {
                            right: 0,
                        }).css(950, {
                            float: 'left',
                            height: 'calc(100%)',
                            width: 'calc(100% - 400px)'
                        }).case(790, size => {
                            console.log(`On size ${790} specific function called.`)
                        });
                    });
                    
                    imgBlk.addElem('img', img => {
                        img.onResize(x => {
                            x.css({
                                height: 'calc(100% - 35px)',
                                objectFit: 'cover',
                                overflow: 'scroll',
                                zoom: 1,
                                lineHeight: '100%'
                            }).css(950, {
                                zoom: 1
                            });
                        });
                        // .bindAttr("src", data => `../${data.imagePath}`, true)
                        this.dom.event('datachanged', (event) => {
                            console.log('datachanged image', event)
                            img.attrs({ src: event.detail.imagePath })
                        });

                        // imgBlk.onResize(x => {
                        //     x.case(950, size => that.buildZoomer(imgBlk, img), true);
                        // });
                    });
                });
        
                // return
                cnt.addElem('div', giftInfo => {
                    giftInfo
                    .addClass('gift-modal__info')
                    .styles({
                        float: 'left',
                        width: '100%',
                        fontFamily: "'Lato', sans-serif",
                    })
                    .onResize(x => {
                        x.css({
                            float: 'left',
                            width: '100%',
                            height: 'auto'
                        }).css(550, {
                            right: 0,
                        }).css(650, {
                            right: 0,
                        }).css(790, {
                            right: 0,
                        }).css(950, {
                            width: '400px',
                            height: '100%',
                        });
                    })
                    .addElem('h4', title => {
                        title.styles({
                            margin: 0,
                            padding: '10px 0 5px 0',
                            textAlign: 'center',
                            fontSize: '30px',
                            lineHeight: '40px',
                            textShadow: '2px 2px 0 rgba(71, 178, 152, .1)',
                            // userSelect: 'none'
                        }).bindText(data => data.giftName, true); //.text(data.name);
                    }).addElem('p', description => {
                        description.styles({
                            margin: 0,
                            padding: '5px 0 10px 0',
                            textAlign: 'justify',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }).bindText(data => data.giftDescription, true);
                    }).addElem('div', price => {
                        price.styles({
                            margin: '20px 0',
                            textAlign: 'left'
                        }).addElem('div', soursePrice => {
                            soursePrice.styles({
                                display: 'inline-block',
                                width: '50%',
                                fontFamily: "'Lato', sans-serif",
                                lineHeight: '20px',
                                textAlign: 'justify',
                                margin: 0
                            })
                            .addElem('span', value => {
                                value.styles({
                                    marginRight: '10px',
                                    color: '#556271',
                                    fontSize: '14px',
                                    verticalAlign: 'top'
                                }).text('Price:');
                            })
                            .addElem('strong', value => {
                                value.styles({
                                    fontFamily: "'Lato', sans-serif",
                                    color: '#B12704',
                                    fontSize: '18px'
                                }).bindText(data => `$${data.giftPrice}`, true);
                            })
                        });
                        
                        if (this.isAuthenticated && this.isUserLoginEnabled && this.isShoppingCartEnabled) {
                            price.addElem('div', totalPrice => {
                                totalPrice.styles({
                                    display: 'inline-block',
                                    width: '50%',
                                    fontSize: '16px',
                                    fontFamily: "'Lato', sans-serif",
                                    lineHeight: '20px',
                                    textAlign: 'right',
                                    margin: 0
                                })
                                .addElem('label', value => {
                                    value.styles({
                                        marginRight: '10px',
                                        fontStyle: 'italic',
                                        color: '#556271',
                                    }).text('Total price:');
                                })
                                .addElem('strong', totalPriceValue => {
                                    totalPriceValue.bindText(data => `$${data.giftPrice * data.itemsCount}`, true);  
                                });
                            });
                        }
                    })

                    if (this.isAuthenticated && this.isUserLoginEnabled && this.isShoppingCartEnabled) {
                        giftInfo.addElem('div', ctrl => {
                            ctrl.addClass('gift-modal__info__ctrl')
                            .addElem('div', countDiv => {
                                countDiv.styles({ width: '50%', float: 'left' })
                                .addElem('ul', ul => {
                                    ul.addClass('counter')
                                    .styles({
                                        float: 'left',
                                        marginBottom: '20px'
                                    });
    
                                    ul.addElem('li', li => {
                                        li.vars.msg2 = "hello!!!";
                                        li.addElem('a', minus => {
                                            minus.attrs({ href: '#'}).text('-').event('click', () => {
                                                let integer = that.data.itemsCount;
                                                if(integer > 0){
                                                    that.data.itemsCount = integer - 1;
                                                    that.updateState(); 
                                                }
                                            })
                                            .bindAction(data => that.taggleBtnActive(minus, data.itemsCount > 0));
                                        });
                                    })
                                    .addElem('li', counter => {
                                        counter.addElem('input', counterInput => {
                                            counterInput
                                            .bindValue(data => data.itemsCount, true)
                                            .event('focusout', () => {
                                                var val = that.tryParseInt(counterInput.element.value, 0);
                                                that.data.itemsCount = val >= 0 ? val : 0;
                                                counterInput.element.value = that.data.itemsCount;
                                                that.updateState();
                                            });
                                        });
                                    })
                                    .addElem('li', li => {
                                        li.addElem('a', plus => {
                                            plus.attrs({ href: '#'}).text('+').event('click', () => {
                                                that.data.itemsCount = that.data.itemsCount + 1;
                                                that.updateState();
                                            });
                                        });
                                    })
                                })
                                .addElem('ul', itemsInCart => {
                                    itemsInCart.addClass('items-in-cart').addClass('sg-hidden')
                                    .addElem('li', itemsCount => {
                                        itemsCount.addClass('add-to-cart-icon')
                                        .addElem('input', count => {
                                            count.addClass('count')
                                            .bindValue(data => {
                                                console.log("this.State", that.State)
                                                console.log("bindValue(data", data)
                                                if(data.itemsInCartCount > 0) {
                                                    itemsInCart.removeClass('sg-hidden')
                                                } else {
                                                    itemsInCart.addClass('sg-hidden')
                                                }

                                                // that.State.viewedProducts.map(function(item) {
                                                //     if(item.giftId === product.id) {
                                                //         item.itemsInCartCount = product.items_count;
                                                //     }
                                                // });
    
                                                return data.itemsInCartCount;
                                            }, true);
                                        })
                                        .addElem('div', countCtl => {
                                            countCtl.addClass('ctrls')
                                            .addElem('a', add => {
                                                add.addClass('icon-chevron-down')
                                                .attrs({href: '#'})
                                                .styles({
                                                    transform: 'rotate(-90deg)'
                                                })
                                                .event('click', () => {
                                                    that.data.itemsInCartCount++;
                                                    that.updateState();
                                                    that.updateShoppingCart();
                                                });
                                            })
                                            .addElem('a', remove => {
                                                remove.addClass('icon-chevron-left')
                                                .attrs({href: '#'})
                                                .event('click', () => {
                                                    that.data.itemsInCartCount--;
                                                    that.updateState();
                                                    that.updateShoppingCart();
                                                });
                                            })
                                        });
                                    })
                                    .addElem('li', removeFromCart => {
                                        removeFromCart.addClass('remove-from-cart').addElem('a', a => {
                                            a.attrs({href: '#'})
                                            .text('delete')
                                            .event('click', () => {
                                                that.data.itemsInCartCount = 0;
                                                that.updateState();
                                                that.updateShoppingCart();
                                            });
                                        });
                                    });
                                });
                            })
    
                            ctrl.addElem('div', orderNowBtn => {
                                orderNowBtn.addClass('gift-modal__info__ctrl__btns')
                                .styles({ width: '50%', float: 'right'})
                                .addElem('a', btn => {
                                    btn.addClass('order-now').attrs({ href: '#'}).text('Order now').event('click', () => {


                                        // that.shoppingCart.addItemToCart({ giftId: this.data.giftId, itemsCount: that.data.itemsCount });
                                        //that.updateShoppingCart();
                                       
    

                                    })
                                    .bindAction(data => that.taggleBtnActive(orderNowBtn, data.itemsCount > 0));
                                })
                                .addElem('a', addToCartBtn => {
                                    addToCartBtn.addClass('add-to-cart').attrs({ href: '#'}).text('Add to cart').event('click', () => {
                                        that.data.itemsInCartCount = that.data.itemsInCartCount + that.data.itemsCount;
                                        that.data.itemsCount = 0;
                                        that.updateShoppingCart();
                                        that.updateState();
                                    })
                                    .bindAction(data => that.taggleBtnActive(addToCartBtn, data.itemsCount > 0));
                                });
                            })
                        })
                    }  
                });


        
                // return false;

            },
            taggleBtnActive(btnDom, isTrue) {
                if(isTrue) {
                    btnDom.styles({ pointerEvents: 'auto', cursor: 'pointer', opacity: 1 })
                } else {
                    btnDom.styles({ pointerEvents: 'none', cursor: 'not-allowed', opacity: 0.6 })
                }
            },
            updateShoppingCart() {
                this.shoppingCart.updateCart({ id: this.data.giftId, items_count: this.data.itemsInCartCount });
            },
            buildZoomer(domElem, img) {
                var that = this;
                
                domElem.addElem('div', zoomer => {
                    zoomer.addClass('zoomer').styles({
                            position: 'absolute',
                            right: '20%',
                            bottom: '5%',
                            width: '300px',
                            height: '36px',
                            margin: 0,
                            padding: 0,
                            lineHeight: '30px',
    
                          }).addElem('div', route => {
                            zoomer.vars.route = route;
                            route.addClass('zoomer__route');
                          }).addElem('div', bullet => {
    
                            bullet.addClass('zoomer__bullet')
                                .styles({ cursor: 'grab' })
                                .event('mousedown', (e) => {
                                    that.clearDefaults(e);
                                    zoomer.addClass('focus');
                                    bullet.styles({ cursor: 'grabbing' });
                                    var offsetX = e.clientX - parseInt(window.getComputedStyle(bullet.element).left);

                                    let imgWidth = window.getComputedStyle(img.element).width;
                                    let imgHeight = window.getComputedStyle(img.element).height;
                                    img.styles({
                                        width: imgWidth,
                                        height: imgHeight,
                                    });
                        
                                    function mouseMoveHandler(e) {
                                        that.clearDefaults(e);
                            
                                        let positionX = e.clientX - offsetX;                                           
                                        let width = parseInt(window.getComputedStyle(zoomer.element).width) - parseInt(window.getComputedStyle(bullet.element).width);


                                        if(positionX >= 0 && positionX <= width) {
                                            let zoomValue = parseInt((positionX/width)*100);
                                            bullet.styles({ left: positionX + 'px' });
                                            // zoomer.vars.route.text(zoomValue > 0 ? `${zoomValue}%` : '');
                                            img.styles({ zoom: 1+positionX/width });
                                        }
                                    }
                        
                                    function reset(e) {
                                        that.clearDefaults(e);
                                        zoomer.removeClass('focus');
                                        bullet.styles({ cursor: 'grab' });
                                        window.removeEventListener('mousemove', mouseMoveHandler);
                                        window.removeEventListener('mouseup', reset);
                                    }
                        
                                    window.addEventListener('mousemove', mouseMoveHandler);
                                    window.addEventListener('mouseup', reset);
                                }).onResize(x => {
                                    x.css({ left: 0 }).css(950, { left: 0 });
                                });
                        });
                        
                });
            },
            loadGiftData(size, giftId, func) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", 'ajax/get/gift?productId=' + giftId, true);
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);
        
                            if(response && response.gift) {
                                func(response.gift);
                            }
                        }
                    }
                }
                xhr.send();
            },
            tryParseInt(value, defaultValue) {
                if (!isNaN(value) && !isNaN(parseInt(value))) {
                    return parseInt(value);
                }
                return defaultValue;
            },
            clearDefaults(e) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }
);
import VCDom  from './vc';
// import RevealOnScroll from './RevealOnScroll';

export default VCDom.Render(
    'categories-block', 
    {
        render(props) {
            this.html = document.querySelector('html');
            this.products = document.getElementById("gifts");

            this.getCategoriesData(this.buildContent.bind(this));
            
            return {
                filterByCategory: this.filterByCategory.bind(this)
            }
        },
        props: {
            sectionName: String
        },
        data: {
            isUserLoginEnabled: false,
            categories: [],
            selectedCategories: [],
        },
        events(vc) {
            var that = this;
           
        },
        watch: {
            categories: [],
        },
        methods: {
            buildContent() {
                var that = this;
                let Observe = this.Observable.init;
                this.dom
                .addId('categories')
                .addClass('page-section')
                .addClass('page-section--green')
                .onResize(x => {
                    x.class(0, 'categories--mobile', true)
                    .class(750, 'categories--min', true)
                    .class(1300, 'categories--max', true);
                })
                .styles({
                    display: 'block',
                    overflowX: 'hidden'
                })
                    .addElem('div', wrapper => {
                        wrapper.addClass('wrapper')
                        .addElem('h2', h2 => {
                            h2
                            .addClass('section-title')
                            .addClass('section-title--iconed')
                            .addClass('section-title--blue')
                            .addElem('strong', title => {
                                title.text(this.sectionName)
                            });
                        }).addElem('div', cnt => {
                            cnt.addClass('row')
                            .addClass('row--gutters-large')
                            .styles({
                                marginTop: '35px'
                            });  

                            if(that.categories.length === 0) {
                                cnt.addClass('cnt--no-content')
                                .styles({
                                    textAlign: 'center',
                                    height: '350px',
                                    lineHeight: '350px'
                                });
                            }
    
                            that.categories.forEach(function(category, i, categories) {
                                // new Observe(category)
                                //     .defineProperty('isSelected', false)
                                //     .observableObject;

                                cnt.addElem('div', categoryElem => {
                                    categoryElem.addClass('category')
                                    .addClass('js-category-blk')
                                    .styles({
                                        float: ( i & 1 ) ? "right" : "left",
                                        width: '50%',
                                        height: 'auto'
                                    })
                                    .addElem('a', a => {
                                        a.attrs({ href: '#' })
                                        .addClass('sg-i-pointer-after')
                                        .addClass('category-name')
                                        .text(category.name)
                                        .event('click', () => a.toggleClass('selected', isAdded => {
                                            category.isSelected = isAdded;
                                            category.subCategories.map(function(item) { 
                                                item.isSelected = isAdded 
                                            })
                                            that.applyFilter(categories);
                                        }));

                                        category.AddSubscription('isSelected', (value) => {
                                            value ? a.addClass('selected') : a.removeClass('selected')
                                        });
                                    })
                                    .addElem('ul', ul => {
                                        ul.addClass('list-none'); 
                                        category.subCategories.forEach(function(subCategory) {
                                            // new Observe(subCategory)
                                            //     .defineProperty('isSelected', false)
                                            //     .observableObject;
                                           
                                            ul.addElem('li', li => {
                                                li.addClass('category__item')
                                                .addElem('a', a => {
                                                    a.attrs({ href: '#' })
                                                    .text(subCategory.name);
                                                    
                                                    if(subCategory.inUse) {
                                                        a.event('click', () => a.toggleClass('selected', isAdded => {
                                                            subCategory.isSelected = isAdded;
                                                            let selectedCount = category.subCategories
                                                                .filter(function(item) {return item.isSelected})
                                                                .map(function(item) { return item.isSelected ? 1 : 0 })
                                                                .reduce((a, b) => a + b, 0);
                                                            category.isSelected = selectedCount === category.subCategories.length;
    
                                                            that.applyFilter(categories);
                                                        }));
    
                                                        subCategory.AddSubscription('isSelected', (value) => {
                                                            value ? a.addClass('selected') : a.removeClass('selected');
                                                        });               
                                                    } else {
                                                        a.addClass('sg-inactive')
                                                    }                                                                                       
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
            },
            filterByCategory() {
                //
            },
            getCategoriesData(func) {  
                var that = this;
                var xhr = new XMLHttpRequest();
                xhr.open("GET", 'ajax/get/categories', true);
                xhr.onload = function(e) {
                    if(xhr.readyState === 4) {
                        if(xhr.statusText === "OK" && xhr.status === 200) {
                            var response = JSON.parse(xhr.response);

                            that.categories = response.categories;

                            that.categories.forEach(function(obj) {
                                init(obj);
                                obj.subCategories.forEach(function(subObj) {
                                    init(subObj);
                                });
                            });
            
                            // that.categories = sampleData;
                            func();
                        }
                    }
                }
                xhr.send();  
                    

                // let sampleData = [{
                //     id: 1,
                //     name: 'Meat arrangements', 
                //     subCategories: [{
                //         id: 1, name: 'meat bouquets'
                //     }, 
                //     {
                //         id: 2, name: 'meat baskets'
                //     }]
                // }, {
                //     id: 2,
                //     name: 'Compositions with flowers', 
                //     subCategories: [{
                //         id: 3, name: 'sweets and flower arrangements'
                //     }, 
                //     {
                //         id: 4, name: 'fruit and flower arrangements'
                //     }]
                // },
                // {
                //     id: 3,
                //     name: 'Candy bouquets', 
                //     subCategories: [{
                //         id: 5, name: 'prebuilt arrangements'
                //     }, 
                //     {
                //         id: 6, name: 'custom arrangements'
                //     }]
                // },
                // {
                //     id: 4,
                //     name: 'Housewarming gift baskets', 
                //     subCategories: [{
                //         id: 7, name: 'prebuilt arrangements'
                //     }, 
                //     {
                //         id: 8, name: 'custom arrangements'
                //     }]
                // }];
                
                let init = (obj) => {
                    obj.subscriptions = [];
                    obj.AddSubscription = (prop, func) => {
                        obj.subscriptions.push({ prop: prop, func: func });
                    };

                    let prop = 'isSelected';
                    let isSelected = false;
                    Object.defineProperty(obj, prop, {
                            get () {
                                return isSelected;
                            },
                            set (value) {
                                if(isSelected !== value) {
                                    isSelected = value;
                                    obj.subscriptions.forEach(function(item) {
                                        item.func(isSelected);
                                    })
                                }                                
                            }
                        }
                    );
                };

                // sampleData.forEach(function(obj) {
                //     init(obj);
                //     obj.subCategories.forEach(function(subObj) {
                //         init(subObj);
                //     });
                // });

                // that.categories = sampleData;
                // func();
            },
            applyFilter(categories) {
                var that = this;
                let productElements = this.products.querySelectorAll('.js-gift')
                let selectedIdsInt = categories
                    .flatMap(c => c.subCategories)
                    .filter(function(item) {return item.isSelected})
                    .map(function(item) { return item.id });

                productElements.forEach(function(elem) {
                    if(selectedIdsInt.some(id => id === that.tryParseInt(elem.getAttribute('data-subgenre-id'))) || selectedIdsInt.length === 0) {
                        elem.parentElement.classList.remove('sg-hidden')
                    } else {
                        elem.parentElement.classList.add('sg-hidden');
                    }
                });
            },
            tryParseInt(value, defaultValue = 0) {
                if (!isNaN(value) && !isNaN(parseInt(value))) {
                    return parseInt(value);
                }
                return defaultValue;
            }
        }
    }
);
import $ from '../../../node_modules/jquery/dist/jquery.js';

import VCDom  from './modules/vc';
VCDom.State.activeModals = [];
VCDom.State.viewedProducts = [];
let appName = 'YellowSparrow';

import Header from './modules/Header';
import SiteWall from './modules/SiteWall';
// import InfoBlock from './modules/InfoBlock';
import GetInTouch from './modules/GetInTouch';


import RevealOnScroll from './modules/RevealOnScroll';
import StickyHeader from './modules/StickyHeader';
import Testimonials from './modules/Testimonials';
import ShoppingCart from './modules/ShoppingCart';
import GiftModal from './modules/GiftModal.js';
import CategoriesBlock from './modules/CategoriesBlock.js';

var gutil = require('gulp-util');

new Testimonials();

var siteHeader = Header({ appName: appName });
var shoppingCart = ShoppingCart({ appName: appName, siteHeader: siteHeader });
// InfoBlock();
SiteWall({ siteHeader: siteHeader });
GiftModal({ appName: appName, shoppingCart: shoppingCart, siteHeader: siteHeader })
CategoriesBlock({ sectionName: 'Arrangements' });
GetInTouch({ appName: appName, siteHeader: siteHeader });


setTimeout(function() {
    new RevealOnScroll($(".category"), "85%");
    new RevealOnScroll($(".js-gift-blk"), "85%");
    new RevealOnScroll($(".testimonial"), "85%");

    new StickyHeader();
}, 500);

// $.uglify().on('error', function(err) {
//     gutil.log(gutil.colors.red('[Error]'), err.toString());
//     this.emit('end');
// });

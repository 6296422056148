class Testimonials {
    constructor(){
        this.listClass = 'testimonial--list';
        this.rowClass = 'testimonial--row';
        this.testimonialsCnt = document.querySelector('.js-testimonials-cnt');
        this.testimonialsMore = document.querySelector('.js-testimonials-more');
        this.testimonialsLess = document.querySelector('.js-testimonials-less');
        this.expandBtn = document.querySelector('.js-testimonials-more .sg-i--btn-expand');
        this.shrinkBtn = document.querySelector('.js-testimonials-less .sg-i--btn-expand');
        this.testimonialsList = [];
        this.event();
    }

    event() {
        if(this.expandBtn) {
            this.expandBtn.addEventListener("click", this.loadMore.bind(this));
            this.shrinkBtn.addEventListener("click", this.minimiseBlock.bind(this));
        }      
        //document.querySelector('js-uri-facebook').addEventListener("click", this.displayFacebookTestimonialWindow.bind(this));
    }

    displayFacebookTestimonialWindow(){
        
    }

    minimiseBlock(){
        this.genarateTestimonials(this.testimonialsList.slice(0, 3), this.rowClass);
        this.shrink();
    }

    loadMore(){  
        if(this.testimonialsList.length === 0) {
            this.showLoader();

            var that = this;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", 'get/ajax/testimonials', true);
            xhr.responseType = "JSON";
            xhr.onload = function(e) {
                var response = JSON.parse(xhr.response);
                that.testimonialsList = response.data;
                that.genarateTestimonials(response.data, that.listClass);
                that.expand();
                that.hideLoader();
            }
            xhr.send();
        } else if(!this.isExpanded()) {
            this.genarateTestimonials(this.testimonialsList, this.listClass);
            this.expand();
        } else {
            this.minimiseBlock();
        }           
    }

    showLoader() {
        this.testimonialsMore.querySelector('.sg-i--btn-expand').classList.add('sg-hidden');
        this.testimonialsMore.querySelector('.sg-loader').classList.remove('sg-hidden');
    }

    hideLoader() {
        this.testimonialsMore.querySelector('.sg-loader').classList.add('sg-hidden');
        this.testimonialsMore.querySelector('.sg-i--btn-expand').classList.remove('sg-hidden');
    }

    genarateTestimonials(data, cssClass){
        let contentStr = "";

        data.forEach(review => {
            contentStr += this.getTestimonialTemplate(review, cssClass);
        });
        
        this.testimonialsCnt.innerHTML = contentStr;
    }

    getTestimonialTemplate(review, cssClass){
        return "<div class='sg-enum-box__unit'>" +
            "<div class='testimonial " + cssClass +"'>" +
                "<div class='x_info'>" +
                    "<div class='x_photo sg-i-facebook'>" +
                        this.getTestimonialPhotoTemplate(review.photo_uri) +
                    "</div>" +
                    // "<h3 class='x_title'>" + review.recommendation_type +"</h3>" +
                    "<h4 class='x_date'>" + review.created_time + "</h4>" +
                "</div>" +
                "<p class='x_txt'>&ldquo;" + review.review_text + "&rdquo;</p>" +
            "</div>" +
        "</div>";   
    }

    getTestimonialPhotoTemplate(uri) {
        return uri ? ("<img src='" + uri + "' alt='Reviewer'>") : "";
    }

    expand(){
        this.testimonialsLess.classList.remove('sg-hidden');
        this.testimonialsMore.classList.add('sg-i--btn-expand-top');
        this.testimonialsCnt.classList.remove("sg-enum-box--row1");
        this.testimonialsCnt.classList.add("sg-enum-box--list");
    }

    shrink(){
        this.testimonialsLess.classList.add('sg-hidden');
        this.testimonialsMore.classList.remove('sg-i--btn-expand-top');
        this.testimonialsCnt.classList.add("sg-enum-box--row1");
        this.testimonialsCnt.classList.remove("sg-enum-box--list");
    }

    isExpanded(){
        return this.testimonialsCnt.classList.contains('sg-enum-box--list');
    }
}

export default Testimonials;